<template>
  <div class="padding-page">
    <h1>Tableau</h1>
    <table>
      <thead>
        <tr>
          <th>Nom Commun</th>
          <th>Nom Latin</th>
          <th>Description</th>
          <th>Etat</th>
          <th>Code Postal</th>
          <th>Quantité</th>
          <th>Disponibilité</th>
          <th>Expire le</th>
          <th>Créé le</th>
          <th>Modifié le</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item  in products">
          <td>{{ item.plantCommonName }}</td>
          <td>{{ item.plantLatinName }}</td>
          <td>{{ item.description }}</td>
          <td>{{ eForm[item.form] }}</td>
          <td>{{ item.postalCode }}</td>
          <td>{{ item.quantity }}</td>
          <td>{{ item.availability ? "oui" : "non" }}</td>
          <td>{{ item.expirationDate }}</td>
          <td>{{ formattedDate(item.createdAt) }}</td>
          <td>{{ formattedDate(item.updatedAt) }}</td>
        </tr>
      </tbody>
    </table> 
  </div>
</template>
<script>  
import axios from 'axios'
export default {
  methods: {
    formattedDate : function(e) {
        return new Date(e).toISOString('fr-FR').split('T')[0]
      }
  },
  mounted() {
    axios.get('https://origan-api-backend.benjami.fr/products')
      .then(response => {
        this.products = response.data

      })
  },
  data() {
    return {
      eForm: {
        SEED : 'Graine',
        CUTTING : "Bouture",
        WHOLE_PLANT : "Plante entière",
        TREE : "Arbre",
        OTHER : "Autre"
      },
      products : [
    ]
  }
},
}
  
</script>
<style>
  .filtre{
    margin-top: 0;
  }

	th{ 
    background-color : var(--main-green);
    color: white;
    padding: 4px 16px;
  }
  td{
    padding: 4px 16px;
  }
	/* Alternance de couleur sur les lignes */
	tr:nth-child(even){
    background-color : #9bd7aa8a;
  }
	tr:nth-child(odd){
    background-color : #ddfae4ac;
  }

  input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  }

  input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  input[type=submit]:hover {
    background-color: #45a049;
  }

  .etat-button{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: var(--main-text);
  padding: 4px 0;
  margin-top: 4px;
}
h3{
  color: var(--main-green);
}
.btn-payant{
  background-color: white;
  border: 1px solid var(--main-green);
  border-radius: 4px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  width: 49%;
  cursor: pointer;
}
.all-btn-payant{
  display: flex;
  align-items: center;
  gap: 2%;
}
</style>