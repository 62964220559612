import { createRouter, createWebHistory } from "vue-router";
import Informations from '@/views/Informations.vue';
import Tableau from '@/views/Tableau.vue';
import Documentation from '@/views/Doc.vue';
import Export from '@/views/Export.vue';
import NoFound from '@/views/NoFound.vue';

const routes = [
  {
    name:'Informations',
    path: '/',
    component: Informations,
    meta:{
      title: 'Informations'
    }
  },
  {
    name:'Tableau',
    path: '/Tableau',
    component: Tableau,
    meta:{
      title: 'Tableau'
    }
  },
  {
    name:'Documentation',
    path:'/documentation',
    component: Documentation,
    meta:{
      title: 'Documentation'
    }
  },
  {
    name:'Export',
    path:'/export',
    component: Export,
    meta:{
      title: 'Export'
    }
  },
  {
    name:'Not Found',
    path:'/:patchMatch(.*)',
    component: NoFound,
    meta:{
      title: '404 Not Found'
    }
  }
];

const router = createRouter(
  {
    history: createWebHistory(),
    routes,
  }
);

router.afterEach((to, from) => {
  document.title = to.meta.title;
});

export default router;