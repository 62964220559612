<template>
   <div class="padding-page">
    <iframe src="https://origan-api-backend.benjami.fr/#" frameborder="0" style="width:100%; height:100%;"></iframe>
  </div>
</template>

<script>
</script>

<style>
  iframe{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
</style>