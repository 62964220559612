<template>
  <div class="padding-page">
    <h1>Informations</h1>
    <h2>Description</h2>
    <p>Ici, nous mettons à disposition les lieux et plantes qui font l'objet d'annonces sur des sites de ventes ou échange entre particuliers.<br>
      Actuellement, il s'agit essentiellement d'Origan.io.<br>
      Si vous souhaitez alimenter cette base de données ou vous en servir, n'hésitez pas à nous contacter 😉 🌱 </p>
    <h2>Details de l'API</h2>
    <div class="api-detail-lign">
      <strong>Identifiant du jeu de données</strong>
      <p>espace_verts</p>
    </div>
    <div class="api-detail-lign">
      <strong>Téléchargements</strong>
      <p>{{ productsCount }}</p>
    </div>
    <div class="api-detail-lign">
      <strong>Thèmes</strong>
      <p>Environnement</p>
    </div>
    <div class="api-detail-lign">
      <strong>Langue</strong>
      <p>Français</p>
    </div>
    <div class="api-detail-lign">
      <strong>Territoire</strong>
      <p>Paris</p>
    </div>
    <div class="api-detail-lign">
      <strong>Dernière modification</strong>
      <p>Le {{lastUpdate}}</p>
    </div>
    <div class="api-detail-lign">
      <strong>Publié</strong>
      <p>Le 2023-03-10 </p>
    </div>
    <div class="api-detail-lign">
      <strong>Créateurs</strong>
      <p>Axelle Blondin, Benjamin Alerte, Valentin Debray, Fanny Maille</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

  export default {
    data() {
      return {
        lastUpdate: '',
        productsCount: 0
      }
    },
    mounted() {
      axios.get('https://origan-api-backend.benjami.fr/products/get/last')
        .then(response => {
          this.lastUpdate = new Date(response.data).toISOString('fr-FR').split('T')[0]

        })
      axios.get('https://origan-api-backend.benjami.fr/products')
        .then(response => {
          this.productsCount = response.data.length

        })

}
  }
</script>

<style>
h2{
  color: var(--main-green);
}

.api-detail-lign{
  display: flex;
  gap: 24px;
  margin-top: 8px;
}
.api-detail-lign strong{
  width: 150px;
  text-align: right;
}

</style>