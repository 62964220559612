<template>
  <div class="menu">
    <a href="/">
      <img src="../assets/logo.png" width="140"/>
    </a>
      
  <nav>
    <router-link class="menu-button" to="/">Informations</router-link>
    <router-link class="menu-button" to="/tableau">Tableau</router-link>
    <router-link class="menu-button" to="/documentation">Documentation</router-link>
    <router-link class="menu-button" to="/export">Export</router-link>
  </nav>
  </div>
</template>

<style>
nav{
  display: flex;
  gap: 32px;
  align-items: center;
}

.menu{
  padding: 0 80px;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 28px rgba(48, 78, 56, 0.08);
}

.menu-button{
  color: var(--main-text);
  text-decoration: none;
  padding: 24px 0 16px 0;
  border-bottom: 4px solid white;
}

.menu-button:hover, .router-link-exact-active{
  color: var(--main-green);
}

.router-link-exact-active{
  border-bottom: 4px solid var(--main-green);
}

</style>