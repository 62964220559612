import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "@fortawesome/fontawesome-free";

createApp(App).use(router).mount("#app");

// TODO: change to real API URL
export const apiUrl = `https://origan-api-backend.benjami.fr/`;
