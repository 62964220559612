<script>
import Menu from './components/Menu.vue'
import './CSS/mereset.css';

export default {
  name: 'App',
  components: {
    Menu
  }
}
</script>

<template>
  <Menu></Menu>
  <div class="container">
    <router-view/>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #1B1B1B;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
  }
</style>

