<template>
  <div class="padding-page">
    <h1>Export</h1>
    <h2>Conditions</h2>
    <p>Les contenus proposés par Origan.api sont open-source, à l’exception des logos et des représentations iconographiques et photographiques qui peuvent être régis par leurs licences propres.<br>
      Le code source de la plateforme est libre et disponible ici : <a href="https://gitlab.com/FannyMaille/origan-vanaprincipia">https://gitlab.com/FannyMaille/origan-vanaprincipia</a></p>
    <button class="btn-green" @click="Export();">Export .csv<CIcon :icon="cilArrowThickBottom" size="s" class="icon"/></button>
  </div>
</template>

<script>
  import { CIcon } from '@coreui/icons-vue';
  import { cilArrowThickBottom } from '@coreui/icons';
  import axios from 'axios'

  export default {
    components: {
      CIcon,
    },
    setup() {
      return {
        cilArrowThickBottom
      }
    },
    methods: {
      Export : function() {
        return axios.get('https://origan-api-backend.benjami.fr/products/export/csv').then((data)=> {
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data.data);
          anchor.target = '_blank';
          anchor.download = 'origan_data.csv';
          anchor.click();
        });
        
      }
    }
  }
</script>

<style scoped>

.btn-green{
  background-color: var(--main-green);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: white;
  border-radius: 20px;
  border: none;
  padding: 12px 28px;
  width: max-content;
  margin-top: 20px;
  align-self: center;
}
.btn-green:hover{
  cursor: pointer;
  transform: scale(90%);
}
.icon{
  width: 15px;
}
</style>